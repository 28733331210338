<template>
  <AuthLayout>
    <p>Welcome Back</p>
    <h1 class="my-2">Login to your account</h1>
    <div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="handleSubmit">
          <Input
            class="mb-2"
            type="email"
            placeholder="Email address"
            v-model="formData.email"
            name="Email"
            rules="required|email"
          />
          <Input
            type="password"
            placeholder="Password"
            v-model="formData.password"
            rules="between:6,21|required"
            name="Password"
            autocomplete="true"
          />
          <div class="flex flex__end">
            <router-link
              :to="{ name: 'forgotPassword' }"
              class="text__dark-grey mt-1 mb-2"
              >Forgot password?</router-link
            >
          </div>
          <Button
            theme="blue"
            :loading="loading"
            :disabled="invalid || loading"
          >
            Login
          </Button>
        </form>
      </ValidationObserver>
      <!-- <div class="flex flex__space-center">
        <div style="width: 43%; background: #d3d4d8; height: 1px"></div>
        <p class="text__light-grey my-3">OR</p>
        <div style="width: 43%; background: #d3d4d8; height: 1px"></div>
      </div>
      <button @click="signinWithGoogle" class="cta__google u-relative mb-2">
        Continue with Google
        <div class="cta__google-icon flex">
          <img src="../../../assets/icons/google-icon.svg" alt="google-icon" />
        </div>
      </button> -->
      <p style="font-size: 1.2rem" class="text__light-grey mt-3">
        All your activity will remain private
      </p>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../../layouts/AuthLayout/Index.vue';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import authService from '../../../services/api/authService';
import { setPrivateHaders } from '../../../services';

export default {
  name: 'Login',
  components: {
    AuthLayout,
    Button,
    Input
  },
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/getLoggedUser',
      userData: 'authnew/getUserData'
    })
  },
  methods: {
    ...mapActions({
      loginUser: 'auth/loginUser'
    }),
    ...mapMutations({
      setLoggedUser: 'auth/setLoggedUser',
      setUserData: 'authnew/setUserData'
    }),
    handleSubmit() {
      this.loading = true;
      authService
        .login(this.formData)
        .then((res) => {
          if (res.data.user.role !== 'seeker') {
            this.$handleError('Unauthorized!');
            return;
          }
          this.$handleSuccess(res);
          this.setUserData(res.data);
          setPrivateHaders(res.data.token);
          this.goToDashboard(res.data);
          localStorage.setItem('otpReceivedAt', Date.now());
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async signinWithGoogle() {
      location.href = 'https://api.infohob.com/auth/google';
    },

    goToDashboard(userData) {
      setTimeout(() => {
        const requiredOption = userData.user.requiredAction;
        let nextPage = 'phone-verification';
        if (requiredOption === 'emailVerification') {
          nextPage = 'verifyEmail';
        } else if (requiredOption === 'completeProfile') {
          nextPage = 'onboarding';
        }
        this.$router.push({
          name: nextPage
        });
      }, 11);
    },
    checkAlreadyLoggedIn() {
      if (this.userData.token) {
        this.goToDashboard(this.userData);
      }
    }
  },
  mounted() {
    // console.log(this.userData);
    const refreshToken = this.$route.query.refreshToken;
    if (refreshToken) {
      console.log(refreshToken);
      authService
        .refreshToken({ refresh_token: refreshToken })
        .then((res) => {
          // this.setUserData(res.data);
          // setPrivateHaders(res.data.token);
          // this.$handleSuccess(res);
          this.setUserData(res.data);
          setPrivateHaders(res.data.token);
          this.goToDashboard(res.data);
          localStorage.setItem('otpReceivedAt', Date.now());
        })
        .catch()
        .finally(() => {});
    } else {
      setTimeout(this.checkAlreadyLoggedIn, 111);
    }
    // const token = this.$route.query.token;
    // const refresh_token = this.$route.query.refresh_token;
    // const on_boarding = this.$route.query.onboarding == "true";
    // if (!token || !refresh_token || on_boarding == undefined) {
    //   console.log("returning...");
    //   return;
    // }
    // axiosPublic.defaults.headers.Authorization = `Bearer ${token}`;
    // const {
    //   realm_access,
    //   resource_access,
    //   preferred_username: username,
    //   email
    // } = jwtDecode(token);
    // const loggedUser = {
    //   realm_access,
    //   resource_access,
    //   username,
    //   email,
    //   token,
    //   refresh_token,
    //   on_boarding
    // };
    // this.setLoggedUser(loggedUser);
    // setTimeout(() => {
    //   this.$router.push({
    //     name: on_boarding ? "home" : "onboarding"
    //   });
    // }, 55);
  }
};
</script>
